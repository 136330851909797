@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	--background: hsla(204, 25%, 3%);
	background: linear-gradient(transparent 100vh, hsl(204, 24%, 5%) 150vh, var(--background)), hsl(204, 24%, 5%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* background: */
progress::-webkit-progress-bar {
	background-color: #212121;
	width: 100%;
}

progress {
	border: solid #777;
	box-shadow: 0px 0px 10px #777;
	border-width: 1px 1px 1px 0px;
	background-color: #212121;
}

/* value: */
progress::-webkit-progress-value {
	background-image: linear-gradient(to right, #2200cb, #6e6aff) !important;
}

progress::-moz-progress-bar {
	background-image: linear-gradient(to right, #2200cb, #6e6aff) !important;
}

progress {
	color: linear-gradient(to right, #2200cb, #6e6aff);
}

.text-xxl {
	font-size: 32px;
}

.bg-blue-gradient {
	background-image: linear-gradient(to bottom, #070022, #02003540) !important;
}

.border-b-gray {
	border-bottom: 2px solid #888;
}

.header-blue {
	background-image: linear-gradient(to bottom, #070022, #02003540) !important;
	border-bottom: 2px solid #888;
	box-shadow: 0px 0px 15px black;
	text-shadow: 0px 0px 10px black;
	text-align: center;
}

.progress-label {
	font-size: .9em;
	position: absolute;
	margin: 0;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

body::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	will-change: transform;
}

body * {
	outline: none;
	font-family:
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		"Helvetica Neue",
		Arial,
		"Noto Sans",
		sans-serif,
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol",
		"Noto Color Emoji";
}

input {
	background-color: rgba(0, 0, 0, 0);
}

.text-start-im {
	text-align: start !important;
}

.text-shadow {
	text-shadow: 0px 0px 2px rgba(0, 0, 0, 1.0);
}

.bright-image-child img {
	filter: brightness(.75);
}

.bright-image-child:hover img {
	filter: brightness(1);
}

.text-shadow-2xl {
	text-shadow:
		1px 0px 3.4px rgba(0, 0, 0, 1.0),
		0px 1px 3.4px rgba(0, 0, 0, 1.0),
		1px 1px 3.4px rgba(0, 0, 0, 1.0),
		0px 0px 3.4px rgba(0, 0, 0, 1.0);
}

.text-shadow-xl {
	text-shadow:
		0px 0px 2.5px rgba(0, 0, 0, 1.0),
		0px 0px 2.5px rgba(0, 0, 0, 1.0);
}

.text-shadow-white {
	text-shadow: 0px 0px 2.5px white;
}

.shadow-custom {
	box-shadow: 0px 0px 20px black;
}

.shadow-custom-xs {
	box-shadow: 0px 0px 10px 2px black;
}

.shadow-gold {
	box-shadow: 0px 0px 10px #fedf84;
}

.text-4-5xl {
	font-size: 36px;
}

.shadow-orange {
	box-shadow: 0px 0px 7px 3px #f7942b;
}

.shadow-inner-custom {
	box-shadow: inset 0 0px 10px 0 rgba(0, 0, 0, 0.4);
}

.shadow-inner-gold {
	box-shadow: inset 0 0px 20px 0 #fedf84;
}

.shadow-gold-small {
	box-shadow: 0px 0px 6px #fedf84;
}

.bg-gray {
	background-color: #181818;
}


.qwer {
	background-color: red;
}


.heroes-icon {
	margin-left: 20%;
}

.dota-plus-header {
	text-shadow: 0px 0px 10px black;
}

.dota-plus-small {
	text-shadow: 0px 0px 10px black;
}


.dota-plus-button-text {
	text-shadow: 0px 0px 8px black;
	letter-spacing: 3px;
	font-size: 30px;
}

.dota-plus-button-text-xs {
	text-shadow: 0px 0px 10px black;
	letter-spacing: 1px;
	font-size: 22px;
}

.text-red {
	color: #f55b5b;
}

.text-green {
	color: #15a323;
}

.text-block-left {
	margin-left: 5%;
	text-shadow: 0px 0px 8px black;
	font-size: 21.5px;
}

.text-block-right {
	margin-right: 5%;
	text-shadow: 0px 0px 8px black;
	font-size: 21.5px;
}


.text-buttom-small {
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 21px;
	text-shadow: 0px 0px 6px black;
}

.text-buttom-small-x {
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 14px;
	text-shadow: 0px 0px 6px black;
}

.text-shards {
	text-shadow: 0px 0px 4px #fedf84;
	font-size: 32px;
	color: #f9e0bb;
}



.leaderboard-table {

	border-spacing: 0px 10px;
	border-collapse: separate;
}

.leaderboard-player-block {
	background-color: #212121;
	border: 1px solid #3a3632;
}

.leaderboard-player-block:hover {
	background-color: #313131;
	border: 1px solid #3a3632;
}

@media screen and (min-width: 650px) {
	.leaderboard-top {
		text-shadow: 0px 0px 3px black;
		font-size: 22px;
		color: #ddd;
		text-align: center;
	}
}

@media screen and (max-width: 650px) {
	.leaderboard-top {
		text-shadow: 0px 0px 3px black;
		font-size: 14px;
		color: #ddd;
		text-align: center;
	}
}

@media screen and (min-width: 650px) {
	.leaderboard-place {
		text-shadow: 0px 0px 4px #fedf84;
		font-size: 25px;
		color: #f9e0bb;
		text-align: center;
		border-right: 1px solid #3a3632;
	}
}

@media screen and (max-width: 650px) {
	.leaderboard-place {
		text-shadow: 0px 0px 4px #fedf84;
		font-size: 18px;
		color: #f9e0bb;
		text-align: center;
		border-right: 1px solid #3a3632;
	}
}


.leaderboard-icon {
	box-shadow: 0px 0px 3px #fedf84;
	margin-left: 4%;
}

@media screen and (min-width: 650px) {
	.leaderboard-name {
		text-shadow: 0px 0px 4px black;
		font-size: 26px;
		color: #ccc;
		margin-left: 5%;
		font-weight: semi-bold;
		letter-spacing: 1px;
	}
}

@media screen and (max-width: 650px) {
	.leaderboard-name {
		text-shadow: 0px 0px 4px black;
		font-size: 18px;
		color: #ccc;
		margin-left: 5%;
		font-weight: semi-bold;
		letter-spacing: 1px;
	}
}

@media screen and (min-width: 650px) {
	.leaderboard-rating {
		text-shadow: 0px 0px 3px #fedf84;
		font-size: 25px;
		color: #f9e0bb;
		font-weight: semi-bold;
		letter-spacing: 1px;
		text-align: center;
		border-right: 1px solid #3a3632;
		border-left: 1px solid #3a3632;
	}
}

@media screen and (max-width: 650px) {
	.leaderboard-rating {
		text-shadow: 0px 0px 3px #fedf84;
		font-size: 18px;
		color: #f9e0bb;
		font-weight: semi-bold;
		letter-spacing: 1px;
		text-align: center;
		border-right: 1px solid #3a3632;
		border-left: 1px solid #3a3632;
	}
}

@media screen and (min-width: 650px) {
	.leaderboard-games {
		text-shadow: 0px 0px 3px #fedf84;
		font-size: 25px;
		color: #f9e0bb;
		text-align: center;
		font-weight: semi-bold;
		letter-spacing: 1px;
		border-right: 1px solid #3a3632;
	}
}

@media screen and (max-width: 650px) {
	.leaderboard-games {
		text-shadow: 0px 0px 3px #fedf84;
		font-size: 18px;
		color: #f9e0bb;
		text-align: center;
		font-weight: semi-bold;
		letter-spacing: 1px;
		border-right: 1px solid #3a3632;
	}
}

.leaderboard-hero img {
	box-shadow: 0px 0px 3px #fedf84;
}


.leaderboard-local-rating {
	text-shadow: 0px 0px 4px black;
	font-size: 30px;
	color: #ddd;
	font-weight: bold;
	letter-spacing: 1px;
}

.leaderboard-local-rating.leaderboard-login {
	font-size: 24px;
}

.leaderboard-local-icon {
	margin-top: 10px;
}

@media screen and (min-width: 650px) {
	.heroes-window-time {
		width: 191px;
	}
}

@media screen and (max-width: 650px) {
	.heroes-window-time {
		width: 120px;
	}
}

.heroes-window-time-border {
	border-right: 1px solid #777;
	border-left: 1px solid #777;
	border-bottom: 1px solid #777;
}

.heroes-icon-notalent {
	margin-left: 20%;
	box-shadow: 0px 0px 20px black;
	background-size: 100%;
}

.heroes-stats-text {
	text-shadow: 0px 0px 4px black;
	font-size: 30px;
	color: #ddd;
	font-weight: bold;

}

.heroes-table {
	border-collapse: separate;
	border-spacing: 0rem 0.8rem;
}

.dota-plus-link:hover {
	color: #fedf84;
	text-shadow: 0px 0px 10px black;
}

.updates-block {
	background-color: #212121;
	box-shadow: 0px 0px 20px black;
}

.minor-update-color {
	color: #7DAC30;
}

.major-update-color {
	color: #BA3434;
}

.text-input {
	border: #777 solid 1px;
	background-color: #212121;
	font-size: 24px;
	text-align: center;
	height: 48px;
}

.text-input-nobg {
	background-color: #212121;
	text-align: start;
	height: 42px;
	padding-left: 10px;
}

.text-align-end {
	text-align: end;
}

.items-build-block-container {
	margin-top: 15px;
	text-align: center;
	font-size: 24px;
}

.last {
	margin-bottom: 0 !important;
	margin-right: 0 !important;
}


.items-build-table-container {
	margin-top: 10px;
	border: 1px solid black;
	border-radius: 5px;
	background-color: #212121;
}

.items-build-img {
	box-shadow: 0px 0px 5px black;
}

.items-build-title-col {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.heroes-builds-block {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
}

.react-tooltip {
	width: fit-content !important;
	opacity: .9 !important;
	background: #000000 !important;
	border: 4px #2A284D solid;
}


.switch-checkbox {
	height: 0;
	width: 0;
	position: absolute;
	visibility: hidden;
}

.switch {
	cursor: pointer;
	width: 100%;
	height: 50px;
	background: #212121;
	position: relative;
	box-shadow: 0px 0px 10px 2px black;
	border-radius: 30px;
	transition: 0.2s;
}

.switch .switch-button {
	position: absolute;
	z-index: 1;
	top: 2px;
	left: 2px;
	width: 50%;
	height: 45px;
	transition: 0.1s;
	border-radius: 30px;
	background: none;
	border: 2px solid #fedf84;
}

.switch-checkbox:checked+.switch-button {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.switch-checkbox:active+.switch-button {
	width: 50%;
}

.switch-labels {
	display: relative;
	z-index: 0;
	height: 100%;
}

.switch-labels span {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 100%;
}

.switch-labels span:first-child {
	left: 0;
}

.switch-labels span:last-child {
	right: 0;
}

.present-header-active {}

.present-header {
	text-align: center;
	font-size: 36px;
	color: #fedf84;
	font-weight: bold;
	cursor: pointer;
	border-bottom: 0;
}